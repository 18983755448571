import { graphql } from "gatsby";
import React from "react";
import styled from "styled-components";
import { useStringsContext } from "../../contexts/strings-context";
import { SEO } from "../../components/SEO";
import { PageProps } from "../../types/global";
import { Fade, AttentionSeeker, Zoom } from "react-awesome-reveal";
import { MdWavingHand } from "react-icons/md";
import WIPSVG from "~/assets/svg/wip";

export const query = graphql`
    query($language: String) {
        strings(language: { eq: $language }) {
            ...LayoutStringsFragment
            aboutme
          }
    }
`;

const MainText = styled.p`
    font-size: 3em;
    color: #d6d4d4;
    font-family: "Roboto";
    font-weight: 700;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
    line-height: 4.25rem;
    padding: 0 0 0 4rem;
    @media (max-width: 1250px) {
      padding: 0;
    }
`;
const Container = styled.div`
    display: flex;
    justify-content: center;
    gap: 1rem;
    flex-flow: column wrap;
    align-items: center;
    padding: 5rem 0;
`;
const Card = styled.div`
  width: 80%;
  display: flex;
  flex-flow: column nowrap;
  gap: 2rem;
  background-color: #30303065;
  padding: 3rem;
  font-family: "Roboto";
  line-height: 1.2rem;
  letter-spacing: 0.5px;
`;
const Header = styled.div`
  font-size: 2rem;
  line-height: 2rem;
`;
const Body = styled.div`
`;
const Location = styled.p`
  font-size: .7rem;
  margin-top: -1rem;
`;

const LegalPage: React.FunctionComponent<PageProps> = (props) => {
  const strings = useStringsContext();

  return (
    <>
      <SEO
        lang={props.pageContext.language}
        title="Saba Hossein - Return and Refund Policy"
        description="Full Stack Web Developer with over 9 years of experience and with a large set of supporting skills. Experienced in Typescript, Javascript, React, Gatsby, Adobe XD, UX/UI Design, Node.js, HTML, CSS, Styled Components, React Spring, AWS, Imgix, HeadLess CMS, and many more."
        keywords={["saba"]}
        url={props.location.pathname}
      />
      <Container>
        <Card>
          <Header>Return, Refund and Cancellation Policy</Header>
          <Body>
            <p>Last updated: November 17, 2022</p>
            <p>Thank you for visiting at Saba Hossein.</p>
            <p>If, for any reason, You are not completely satisfied with a purchase We invite You to review our policy on refunds and returns. This Return and Refund Policy has been created with the help of the TermsFeed Return and Refund Policy Generator.</p>
            <p>The following terms are applicable for any products that You purchased with Us.</p>
          </Body>
          <Header>Interpretation and Definitions</Header>
          <Header style={{fontSize: "1.6rem"}}>Interpretation</Header>
          <Body>
            <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
          </Body>
          <Header>Definitions</Header>
          <Body>
            <ul>
            <li>For the purposes of this Return and Refund Policy:</li>

            <li><b>Company</b> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Saba Hossein, Row house no - 29, Hermes Heritage Phase -1, Shastri Nagar, Nagar Road, Pune.</li>

            <li><b>Website</b> refer to the items offered for sale on the Service.</li>

            <li><b>Orders</b> mean a request by You to purchase Website from Us.</li>

            <li><b>Service</b> refers to the Website.</li>

            <li><b>Website</b> refers to Saba Hossein, accessible from https://www.sabahossein.com</li>
            <li><b>You</b> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
            </ul>
          </Body>
          <Header>Your Order Cancellation Rights</Header>
          <Body>
            <p>You are entitled to cancel Your Order within 45 days without giving any reason for doing so.</p>

            <p>The deadline for cancelling an Order is 45 days from the date on which You received the Website or on which a third party you have appointed, who is not the carrier, takes possession of the product delivered.</p>

            <p>In order to exercise Your right of cancellation, You must inform Us of your decision by means of a clear statement. You can inform us of your decision by:</p>
            <ol>
                <ul>By email: support@sabahossein.com</ul>

                <ul>By visiting this page on our website: https://www.sabahossein.com/contact</ul>

                <ul>By phone number: 9637304919</ul>
            </ol>
            <p>We will reimburse You no later than 14 days from the day on which We receive the returned Website. We will use the same means of payment as You used for the Order, and You will not incur any fees for such reimbursement.</p>
          </Body>
          <Header>Conditions for Returns</Header>
          <Body>
            <p>In order for the Website to be eligible for a return, please make sure that:</p>
            <ol>
                <ul>The supply of Website made to Your specifications or clearly personalized.</ul>
                <ul>The supply of Website which according to their nature are not suitable to be returned, deteriorate rapidly or where the date of expiry is over.</ul>
                <ul>The supply of Website which are not suitable for return due to health protection or hygiene reasons and were unsealed after delivery.</ul>
                <ul>The supply of Website which are, after delivery, according to their nature, inseparably mixed with other items.</ul>
            </ol>
            <p>We reserve the right to refuse returns of any merchandise that does not meet the above return conditions in our sole discretion.</p>
            <p>Only regular priced Website may be refunded. Unfortunately, Website on sale cannot be refunded. This exclusion may not apply to You if it is not permitted by applicable law.</p>
          </Body>
          <Header>Gifts</Header>
          <Body>
            <p>If the Website were marked as a gift when purchased and then shipped directly to you, You'll receive a gift credit for the value of your return. Once the returned product is received, a gift certificate will be mailed to You.</p>
            <p>If the Website weren't marked as a gift when purchased, or the gift giver had the Order shipped to themselves to give it to You later, We will send the refund to the gift giver.</p>
          </Body>
          <Header>Cancellation</Header>
          <Body>
            <p>Once purchased the Website cannot cancelled.</p>
          </Body>
          <Header>Contact Us</Header>
          <Body>
            <p>If you have any questions about our Returns and Refunds Policy, please contact us:</p>
            <ol>
                <li>By email: support@sabahossein.com</li>

                <li>By visiting this page on our website: https://www.sabahossein.com/contact</li>

                <li>By phone number: 9637304919</li>
            </ol>
          </Body>
        </Card>

      </Container>
    </>
  )
}

export default LegalPage
